/*
 * This interceptor adds access token to all calls. It also manages auth info, specifically access token, refresh token and
 * expiration date and time. It also takes care of refreshing access token when it is about to expire.
 */
// See: https://code.amazon.com/packages/AWSNovaGeneralWebsite/blobs/mainline/--/src/services/interceptors/authInterceptor.ts

import AnalyticEventKeys from "../helper/AnalyticEventKeys";
import MobileAnalyticsHelper from "../helper/MobileAnalyticsHelper";
import PUMAMetricHelper from "../helper/PUMAMetricHelper";
import Logger from "../utils/Logger";
import BearerTokenUtils from "./BearerTokenUtils";
import Constants from "../constants/Constants";
import { v4 as uuidv4 } from 'uuid';
import MeshClient from "../client/MeshClient";
import NativeMeshInteractor from "./NativeMeshInteractor";

let dolphinRequestId = "";

// Export Interceptors
export const authInterceptorBuilder = (clientAxiosInstance) => {
    return {
        onBeforeRequest,
        onRequestError,
        onResponseSuccess,
        onResponseError: onResponseErrorBuilder(clientAxiosInstance),
    };
};

// Interceptors
const onBeforeRequest = async (config) => {
    dolphinRequestId = uuidv4();
    checkInternetConnectivity();
    try {
        const logConfig = (({ method, url, headers, data, params}) => ({ method, url, headers, data, params}))(config);
        Logger.log.info("Auth Interceptor Request", applyDolphinRequestId(logConfig));
        await applyAccessToken(config);
        return config;
    } catch (error) {
        Logger.log.error("Auth Interceptor Before Request Error", error, applyDolphinRequestId());
        throw error;
    }
};

const onRequestError = (error) => {
    Logger.log.error("Auth Interceptor Request Error", error, applyDolphinRequestId());
    throw error;
};

const onResponseSuccess = async (response) => {
    const {config, ...logResponse} = response;
    Logger.log.info("Auth Interceptor Response Success", applyDolphinRequestId(logResponse));
    return response;
};

const onResponseErrorBuilder = (axiosInstance) => async (error) => {
    Logger.log.error("Auth Interceptor Response Error", error, applyDolphinRequestId());
    throw error;
};

async function applyAccessToken(config) {
    let accessToken = "";
    let startTime = Date.now();
    try {
        accessToken = BearerTokenUtils.accessToken;
        let accessTokenRetryCount = 2;
        while (!accessToken && accessTokenRetryCount--) {
            if (MeshClient.webSocketConnection.readyState === WebSocket.OPEN) {
                await BearerTokenUtils.setAccessToken();
            } else {
                Logger.log.info('Attempting WS Connection from applyAccessToken');
                await MeshClient.connectWebSocket();
            }
            accessToken = BearerTokenUtils.accessToken;
        }
        if(!accessToken) {
            Logger.log.error('MAP Token Failure', e, applyDolphinRequestId());
            PUMAMetricHelper.publishLatencyToDolphinCSM(startTime, "AccessTokenRequest");
            MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.ACCESS_TOKEN_EXCHANGE, startTime, true);
        }
    } catch (e) {
        Logger.log.error('Exception in MAP Token Failure', e, applyDolphinRequestId());
        PUMAMetricHelper.publishLatencyToDolphinCSM(startTime, "AccessTokenRequest");
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.ACCESS_TOKEN_EXCHANGE, startTime, true);
    }
    // Append access token in the header
    if (accessToken) {
        config.headers["x-amz-access-token"] = accessToken;
    }
    return config;
}



const applyDolphinRequestId = (object = {}) => {
    return {...object, dolphinClientRequestId: dolphinRequestId};
};

const checkInternetConnectivity = () => {
    if (!navigator.onLine) {
        throw new Error(Constants.ErrorCode.DEVICE_OFFLINE);
    }
};