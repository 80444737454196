import React, {Component} from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Checkbox from "@amzn/meridian/checkbox";
import PropTypes from "prop-types";
import HeaderView from "../HeaderView";
import {Logger} from "@amzn/dolphin-web-framework";
import {getContainerId} from "../Utils/ScannerUtils";
import UIUtils from "../Utils/UIUtils";

const dolphinBus = new BroadcastChannel('DOLPHIN_BUS');

class ScanWithCodeView extends Component {

    keyDown = (e) => {
        if (e.key === "Enter") {
            Logger.log.info("Scan received via " + e.key + " " + this?.scanRef?.value);
            if (!this.scanRef.value) {
                Logger.log.info("Empty Scan received in ScanWithCodeView");
                this.requestFocus(20);
                return;
            }
            const data = getContainerId(this.scanRef.value);

            this.propagateScan(data);

            this.scanRef.value = "";
        }
    }

    propagateScan = (data) => {
        if (UIUtils.isScanEventAllowed()) {
            this.props.onSubmit(data.trim());
        } else {
            Logger.log.info("Scan Throttled");
        }
    };

    requestFocus = (time) => {
        if (this.props.focusLock) {
            // Set read only true then false to avoid keyboard popping up
            setTimeout(() => {
                if (this.scanRef && (document.activeElement !== this.scanRef || this.scanRef.readOnly)) {
                    this.scanRef.readOnly = true;
                    this.scanRef.focus();
                    this.scanRef.readOnly = false;
                }
            }, time);
        }
    }

    eventListener = (event) => {
        try {
            const eventData = event.data;
            if (eventData === "disableScan") {
                this.scanRef.inputMode = "none";
            } else if (eventData === "enableScan") {
                this.scanRef.inputMode = "text";
            }
        } catch (JSONParseException) {
            Logger.log.warn("Enable to parse event : " + event.data);
        }
    }

    componentDidUpdate() {
        Logger.log.info("ScanWithCodeView Update");
        this.requestFocus(5);
    }

    componentDidMount() {
        Logger.log.info("ScanWithCodeView Mount");
        this.requestFocus(20);
        dolphinBus.addEventListener('message', this.eventListener);
        window.addEventListener('visibilitychange', ()=>this.onVisibilityChange(this));
        window.addEventListener('keydown', this.onWindowKeyDown);
    }

    componentWillUnmount() {
        Logger.log.info("ScanWithCodeView Unmount");
        dolphinBus.removeEventListener('message', this.eventListener);
        window.removeEventListener('visibilitychange', this.onVisibilityChange);
        window.removeEventListener('keydown', this.onWindowKeyDown);
    }

    onWindowKeyDown(event) {
        if(event.key === "Tab") {
            Logger.log.info("Tab keyDown event received");
            event.preventDefault();
            event.stopPropagation();
        }
    }

    onVisibilityChange(context) {
        Logger.log.info("Visibility state changed to " + document.visibilityState);
        if(document.visibilityState === "visible") {
            if(context.scanRef) {
                context.scanRef.readOnly = true;
            }
        }
    }

    onBlur = () => {
        this.requestFocus(20);
        Logger.log.info("onBlur");
    };

    render() {
        const {primaryTitle, secondaryTitle, image, button, checkbox} = this.props;
        return (
            <Column height="100%" heights="fill">
                <Column alignmentHorizontal="center">
                    {
                        (primaryTitle || secondaryTitle) &&
                        <HeaderView primaryTitle={primaryTitle} secondaryTitle={secondaryTitle}/>
                    }
                    {
                        image &&
                        <Row spacing={"none"} width="70%">
                            <img src={image} alt="Package Scan" height="fit-content" style={{width: '100%'}}/>
                        </Row>
                    }
                    {this.props.onSubmit &&
                    <div style={{zIndex: -1, position: "absolute", opacity: 0}}>
                        <input ref={(element) => {
                            this.scanRef = element;
                        }}
                               autoFocus={true}
                               autoComplete={"off"}
                               readOnly={"readOnly"}
                               type="text"
                               onKeyDown={this.keyDown}
                               inputMode={"text"}
                               onBlur={this.onBlur}/>
                    </div>}
                    {checkbox &&
                    <Column alignmentHorizontal="left" width="100%" spacingInset="none none none medium">
                        <Checkbox checked={checkbox.checked} onChange={checkbox.onChange}>
                            {checkbox.label}
                        </Checkbox>
                    </Column>
                    }
                </Column>
                {button &&
                <Column width="100%" alignmentVertical="bottom">
                    <Button onClick={button.onClick} size="large" type="secondary">{button.title}</Button>
                </Column>
                }
            </Column>
        )
    }
}


ScanWithCodeView.propTypes = {
    primaryTitle: PropTypes.shape({
        title: PropTypes.string,
        size: PropTypes.string,
        type: PropTypes.string
    }),
    secondaryTitle: PropTypes.shape({
        title: PropTypes.string,
        size: PropTypes.string,
        type: PropTypes.string
    }),
    image: PropTypes.string,
    button: PropTypes.shape({
        title: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    }),
    onSubmit: PropTypes.func,
    focusLock: PropTypes.bool,
    checkbox: PropTypes.shape({
        label: PropTypes.string.isRequired,
        checked: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired
    })
}

export default ScanWithCodeView;